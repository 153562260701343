.HomePage {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.HomePage-header {
    padding: 1rem 2rem 1rem 2rem;
}

.HomePageContent {
    height: 100%;
    padding: 0 2rem 4rem 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-y: auto;
}