.MapPage {
    text-align: center;
    /* overflow: hidden; */
}

.mapboxgl-ctrl-bottom-right {
    opacity: 0.4;
}

.MapPage-action-button {
    bottom: 8rem;
    left: 0.5rem;
    position: absolute;
}
