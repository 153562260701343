.SearchPage {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.SearchPage-topSearchNav {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    padding: 2rem 2rem 0 2rem;
}

.SearchPage-searchBar {
    width: 100%;
}

.SearchPageContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: hidden;
}
