.AccountPage {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.AccountPageContent {
    height: 100%;
    padding: 2rem;
    margin-bottom: 3rem;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-y: auto;
}

.AccountPage-saved-park {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.AccountPage-saved-park-information {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.AccountPage-saved-park-information-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}
