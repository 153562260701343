.SearchResult {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    overflow: auto;
    padding: 0 2rem 6rem 2rem;
}

.SearchResult-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    gap: 1rem;
}

.SearchResult-buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

.SearchResult-multiRestrictionDescriptions {
    display: flex;
    gap: 1rem;
}

.SearchResult-Cards {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.SearchResult-information {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding: 1rem 0;
}