.AddPointSection-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.AddPointSection-user {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
}

.AddPointSection-info {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    padding-left: 2.5rem;
    padding-bottom: 2rem;
}

.AddPointSection-into-capitalize-first-letter::first-letter {
    text-transform: uppercase;
}

.AddPointSection-buttons {
    padding-top: 1rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
