.ReviewSection-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.ReviewSection-user {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
}

.ReviewSection-review {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    padding-left: 2.5rem;
}

.ReviewSection-review-capitalize-first-letter::first-letter {
    text-transform: uppercase;
}
